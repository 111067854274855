import { getToken, removeToken, setToken } from '@/helpers/token';
import { REMOVE_TOKEN, SET_TOKEN } from '@/store/mutations';

export default {
    namespaced: true,
    state: {
        token: getToken(),
    },
    getters: {
        isAuthenticated: state => !!state.token,
    },
    mutations: {
        [SET_TOKEN]: (state, token) => {
            state.token = token;
            setToken(token);
        },
        [REMOVE_TOKEN]: () => {
            removeToken();
            window.location.reload();
        },
    },
};
