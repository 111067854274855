import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/i18n/lang/en';
import ru from '@/i18n/lang/ru';
import { LS_APP_LOCALE_KEY } from '@/constants/localStorage';

Vue.use(VueI18n);

export default new VueI18n({
    silentTranslationWarn: 'production' === process.env.VUE_APP_ENV,
    locale: localStorage.getItem(LS_APP_LOCALE_KEY) || process.env.VUE_APP_I18N_LOCALE || 'ru',
    fallbackLocale: process.env.VUE_APP_I18N_LOCALE || 'en',
    messages: {
        en,
        ru,
    },
});
