import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import user from '@/store/modules/user';
import { isMobile } from '@/helpers/common';
import { SET_LOADING } from '@/store/mutations';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loading: false,
        isMobile: isMobile(),
    },
    mutations: {
        [SET_LOADING]: (state, loading) => (state.loading = loading),
    },
    modules: {
        auth,
        user,
    },
});
