import axios from 'axios';
import i18n from '@/i18n';
import store from '@/store';
import { REMOVE_TOKEN } from '@/store/mutations';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: { 'Content-Type': 'application/json' },
});

apiClient.interceptors.request.use(config => {
    const token = store.state.auth.token;

    if (token) {
        config.headers['Authorization'] = token;
    }

    config.headers['X-localization'] = i18n.locale;

    return config;
});

apiClient.interceptors.response.use(
    response => response,
    async error => {
        const excludeEndpoints = ['user-cabinet-api/auth/request-login-code', 'user-cabinet-api/auth/login', 'user-cabinet-api/auth/password-change'];

        const isExcludedEndpoint = excludeEndpoints.includes(error.config.url.replace(`${apiClient.defaults.baseURL}/`, ''));

        // TODO: remove when fixed in backend
        const isInvalidCredentials = 'Invalid credentials' === error.response.data.message;

        if (401 === error.response.status && !isExcludedEndpoint && !isInvalidCredentials) {
            store.commit(`auth/${REMOVE_TOKEN}`);
        }

        return Promise.reject(error);
    }
);

export default apiClient;
