import store from '@/store';
import { SET_LOADING, SET_USER } from '@/store/mutations';
import { getUser } from '@/services/skinsPaymentApi';

const setUser = async () => {
    if (store.getters['auth/isAuthenticated'] && !store.state.user.user) {
        store.commit(SET_LOADING, true);

        const { data } = await getUser();

        store.commit(`user/${SET_USER}`, data);

        store.commit(SET_LOADING, false);
    }
};

export const auth = ({ next }) => (store.getters['auth/isAuthenticated'] ? next() : next({ name: 'login' }));

export const guest = ({ next }) => (store.getters['auth/isAuthenticated'] ? next({ name: 'account' }) : next());

export const authTfa = async ({ next }) => {
    await setUser();
    return store.getters['user/hasTfa'] ? next() : next({ name: 'tfa' });
};

export const guestTfa = async ({ next }) => {
    await setUser();
    return !store.getters['user/hasTfa'] ? next() : next({ name: 'account' });
};
