import _ from 'underscore';
import Vue from 'vue';
import Toast from 'vue-toastification';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import App from '@/App';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import '@/validation';

Vue.config.productionTip = false;

Vue.use(Toast, {
    closeButton: false,
    closeOnClick: false,
    draggable: false,
    hideProgressBar: true,
    icon: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    shareAppContext: true,
});

Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    loaderOptions: {
        autoHideBadge: true,
    },
});

Vue.directive('click-outside', {
    bind: (el, binding, node) => {
        el.clickOutsideEvent = event => {
            const belongsToDatepicker = store.state.isMobile
                ? event.target === document.getElementsByTagName('body')[0] ||
                  _.intersection(
                      _.union(Array.from(event.target.classList), event.target.offsetParent ? Array.from(event.target.offsetParent.classList) : []),
                      ['air-datepicker', 'air-datepicker-overlay']
                  ).length
                : false;

            if (el !== event.target && !el.contains(event.target) && !belongsToDatepicker) {
                node.context[binding.expression](event);
            }
        };

        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: el => document.body.removeEventListener('click', el.clickOutsideEvent),
});

Vue.directive('click-self', {
    bind: (el, binding, node) => {
        el.clickSelfEvent = event => {
            if (el === event.target) {
                node.context[binding.expression](event);
            }
        };

        document.body.addEventListener('click', el.clickSelfEvent);
    },
    unbind: el => document.body.removeEventListener('click', el.clickSelfEvent),
});

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app');
