import i18n from '@/i18n';
import { SET_USER } from '@/store/mutations';

export default {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        hasTfa: state => state.user && state.user.hasOwnProperty('2fa_required') && !state.user['2fa_required'],
    },
    mutations: {
        [SET_USER]: (state, user) => {
            state.user = user;

            if (user.locale) {
                i18n.locale = user.locale;
            }
        },
    },
};
