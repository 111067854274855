<template>
    <div :class="{ 'loader-wrapper': true, 'loader-wrapper_absolute': absolute, 'loader-wrapper_fixed': fixed }">
        <div class="loader" />
    </div>
</template>

<script>
    export default {
        name: 'Loader',
        props: {
            absolute: {
                type: Boolean,
                default: false,
            },
            fixed: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .loader-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &_absolute,
        &_fixed {
            top: 0;
            left: 0;
        }

        &_absolute {
            position: absolute;
        }

        &_fixed {
            position: fixed;
            background-color: #0e1119;
            z-index: 9999;
        }
    }

    .loader {
        display: inline-block;
        width: 6rem;
        height: 6rem;
        border: 0.3rem solid #3760cb;
        border-bottom-color: transparent !important;
        border-radius: 50%;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
