import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth, guest, authTfa, guestTfa } from '@/router/middlewares';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/pages/Home'),
        },
        {
            path: '/auth/registration',
            name: 'auth-registration',
            component: () => import('@/pages/auth/Registration'),
            meta: {
                middleware: [guest],
                title: 'router.titles.auth.registration',
            },
        },
        {
            path: '/auth/registration/code',
            name: 'auth-registration-code',
            component: () => import('@/pages/auth/RegistrationCode'),
            meta: {
                middleware: [guest],
                title: 'router.titles.auth.registration_code',
            },
        },
        {
            path: '/auth/registration/domain',
            name: 'auth-registration-domain',
            component: () => import('@/pages/auth/RegistrationDomain'),
            meta: {
                middleware: [guest],
                title: 'router.titles.auth.registration_domain',
            },
        },
        {
            path: '/auth/login',
            name: 'auth-login',
            component: () => import('@/pages/auth/Login'),
            props: true,
            meta: {
                middleware: [guest],
                title: 'router.titles.auth.login',
            },
        },
        {
            path: '/ayrg/login/code',
            name: 'auth-login-code',
            component: () => import('@/pages/auth/LoginCode'),
            props: true,
            meta: {
                middleware: [guest],
                title: 'router.titles.auth.login_code',
            },
        },
        {
            path: '/auth/new-password',
            name: 'auth-new-password',
            component: () => import('@/pages/auth/NewPassword'),
            meta: {
                middleware: [guest],
                title: 'router.titles.auth.new_password',
            },
        },
        {
            path: '/auth/new-password/code',
            name: 'auth-new-password-code',
            component: () => import('@/pages/auth/NewPasswordCode'),
            meta: {
                middleware: [guest],
                title: 'router.titles.auth.new_password_code',
            },
        },
        {
            path: '/auth/new-password/setup',
            name: 'auth-new-password-setup',
            component: () => import('@/pages/auth/NewPasswordSetup'),
            meta: {
                middleware: [guest],
                title: 'router.titles.auth.new_password_setup',
            },
        },
        {
            path: '/application',
            name: 'application',
            component: () => import('@/pages/Application'),
            meta: {
                title: 'router.titles.application',
            },
        },
        {
            path: '/pay',
            name: 'pay',
            component: () => import('@/pages/Pay'),
            meta: {
                title: 'router.titles.pay',
            },
        },
        {
            path: '/projects',
            name: 'projects',
            component: () => import('@/pages/project/Projects'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.projects',
            },
        },
        {
            path: '/projects/:id/statistics',
            name: 'projects.statistics',
            component: () => import('@/pages/project/Statistics'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.statistics',
            },
        },
        {
            path: '/account',
            name: 'account',
            component: () => import('@/pages/Account'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.account',
            },
        },
        {
            path: '/tfa',
            name: 'tfa',
            component: () => import('@/pages/Tfa'),
            meta: {
                middleware: [auth, guestTfa],
                title: 'router.titles.tfa',
            },
        },
        {
            path: '/docs',
            name: 'docs',
            component: () => import('@/pages/Docs'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.docs',
            },
        },
        {
            path: '/faq',
            name: 'faq',
            component: () => import('@/pages/Faq'),
            meta: {
                middleware: [auth, authTfa],
                title: 'router.titles.faq',
            },
        },
        {
            path: '*',
            name: 'page-not-found',
            component: () => import('@/pages/PageNotFound'),
        },
    ],
});

const nextFactory = (context, middleware, index) =>
    middleware[index]
        ? parameters =>
              !parameters ? middleware[index]({ ...context, next: nextFactory(context, middleware, index + 1) }) : context.next(parameters)
        : context.next;

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

        const context = { from, next, router, to };

        return middleware[0]({ ...context, next: nextFactory(context, middleware, 1) });
    }

    return next();
});

export default router;
