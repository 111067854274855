<template>
    <div id="app">
        <loader v-if="loading" fixed />

        <router-view v-else />
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { localeChanged } from 'vee-validate';
    import { changeLocale } from '@/services/skinsPaymentApi';
    import { LS_APP_LOCALE_KEY } from '@/constants/localStorage';
    import Loader from '@/components/Loader';

    export default {
        components: {
            Loader,
        },
        computed: {
            ...mapGetters('auth', ['isAuthenticated']),
            ...mapState(['loading']),
        },
        methods: {
            setDocumentTitle(route) {
                document.title = this.$i18n.t(route.meta.title || 'router.titles.default');
            },
            setVh() {
                document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

                window.addEventListener('resize', function () {
                    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
                });
            },
        },
        watch: {
            '$i18n.locale': function (locale) {
                this.setDocumentTitle(this.$route);

                localStorage.setItem(LS_APP_LOCALE_KEY, locale);

                localeChanged();

                if (this.isAuthenticated) {
                    changeLocale(locale);
                }
            },
            $route(to) {
                this.setDocumentTitle(to);
            },
        },
        mounted() {
            this.setVh();
        },
    };
</script>

<style lang="scss">
    @import '@/assets/styles/fonts';
    @import '@/assets/styles/vue-toastification';

    html {
        background-color: #0e1119;
        font-size: 10px;

        * {
            margin: 0;
            padding: 0;
            background: none;
            border: none;
            box-sizing: border-box;
            list-style-type: none;
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            font-style: normal;
            font-weight: inherit;
            line-height: inherit;
            text-decoration: none;

            &:hover,
            &:active,
            &:focus,
            &:focus-visible {
                outline: 0;
            }
        }
    }

    body {
        color: #ccd1de;
        font-family: 'Gotham Medium', sans-serif;
        font-size: 1.3rem;
        font-weight: 350;
        line-height: 1;
        overflow: hidden;
    }

    a {
        color: #3760cb;
        transition: color 0.3s, opacity 0.3s;

        &:hover {
            color: #557ce2;
        }
    }

    button {
        cursor: pointer;
    }

    #app,
    .air-datepicker-global-container {
        .air-datepicker-overlay {
            background: rgba(14, 17, 25, 0.9);
            box-shadow: 0 1rem 6.4rem rgba(0, 0, 0, 0.64);
            backdrop-filter: blur(1rem);
        }

        .air-datepicker {
            --adp-mobile-day-cell-height: 4.2rem;

            width: 100%;
            min-width: 24.4rem;
            border-radius: 0.8rem;
            border: none;
            background: #1c2132;
            box-shadow: 0 1.6rem 5.4rem rgba(18, 18, 25, 0.6);

            &:not(.-is-mobile-) {
                margin-top: 0.5rem;
            }

            &.-is-mobile- {
                width: 31.4rem;

                .air-datepicker-cell.-day- {
                    height: 4.2rem;
                }
            }

            &--pointer {
                display: none;
            }

            &--content {
                padding: 1rem;
                background-color: #191e2d;
            }

            &--buttons {
                border-top-color: #252c42;
            }
        }

        .air-datepicker-nav {
            border-bottom-color: #252c42;

            &--action {
                &:hover {
                    background-color: transparent;

                    path {
                        stroke: #dadae9;
                    }
                }

                path {
                    stroke: #4a5768;
                    transition: stroke 0.3s;
                }

                &[data-action='prev'] path {
                    d: path('M 18 10 l -6 6 l 6 6');
                }

                &[data-action='next'] path {
                    d: path('M 13 10 l 6 6 l -6 6');
                }
            }
        }

        .air-datepicker-body {
            &--day-name {
                color: #505871;
                font-family: 'Gotham Bold', sans-serif;
                font-size: 1.1rem;
                font-weight: 700;
            }
        }

        .air-datepicker-cell {
            border-radius: 0;
            color: #8495b2;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.4rem;
            font-weight: 325;

            &.-current-,
            &.-selected-,
            &.-current-.-focus-,
            &.-selected-.-focus- {
                background-color: #435481 !important;
                color: #ebebf4 !important;
            }

            &.-focus-,
            &.-in-range-,
            &.-range-from-,
            &.-range-to-,
            &.-day-.-other-month-.-in-range-,
            &.-day-.-other-month-.-focus-,
            &.-year-.-other-decade-.-in-range-,
            &.-year-.-other-decade-.-focus- {
                border: none;
                border-radius: 0;
                background: #1d253d;
                color: #759bc8;
            }

            &.-day-.-other-month-,
            &.-day-.-other-month-.-in-range-,
            &.-year-.-other-decade-,
            &.-year-.-other-decade-.-in-range- {
                color: #4e5a6f;
            }
        }

        .air-datepicker-nav--title,
        .air-datepicker-button {
            &:hover {
                background-color: transparent;

                &,
                span {
                    color: #4a5768;
                }
            }

            &,
            span {
                color: #dadae9;
                font-family: 'Gotham Bold', sans-serif;
                font-size: 1.4rem;
                font-weight: 700;
                transition: color 0.3s;
            }
        }
    }

    #app {
        height: calc(var(--vh, 1vh) * 100);
    }
</style>
